<template>
  <div>
    <div class="page-heading relative">
      <h2 class="no-margin">Produtos</h2>
      <router-link
        :to="'/products/new'"
        class="btn btn-primary pull-right ml-5 inherit btn-heading"
        ><span><i class="mdi mdi-plus-circle-outline"></i></span> Cadastrar produto
      </router-link>
      <a class="btn btn-info pull-right ml-5 inherit btn-heading" type="button" href="/product/params/"><span><i class="mdi mdi-settings"></i></span> Categorias</a>
    </div>
    <br>
    <div class="row">
          <div class="col-sm-12">
            
              <div class="alert alert-warning alert-stock">
                <p>A última reposição do seu estoque foi em 23 de setembro, 2022 às 13:19</p>
              </div>
          
          </div>
        </div>
    <div class="row">
      <div class="col-lg-6 m-bottom-0">
        <div class="card m-bottom-0">
          <div class="card-body">
            <div class="transaction-progress">
              <div>
                <h2 class="pull-left text-left font-w-100 ng-binding" style=" margin-top: -5px;">Estoque total: 0</h2>
                <button class="btn btn-default  pull-right info-card" style="padding: 0px !important;" type="button" data-placement="top" data-toggle="popover" title="" data-content="As Informações de vendas são calculadas de acordo com a última reposição do estoque" data-original-title="Resumo do estoque"><i class="fas fa-question"></i></button>
                <div style="clear: both;"></div>
              </div>
              
              <div class="item">
                <strong class="pull-right ng-binding" ng-bind-html="Product.products.report.free_count | unsafe">0</strong>
                <p class="text-muted"> <i class="mdi mdi-checkbox-blank-circle-outline text-info"></i> Unidades
                  vendidas</p>
                <div class="progress progress-bar-success-alt">
                  <div class="progress-bar progress-bar-success progress-bar-striped" role="progressbar" aria-valuenow="56" aria-valuemin="0" aria-valuemax="100" style="width:0%">
                  </div>
                </div>
              </div>
              <div class="item">
                <strong class="pull-right ng-binding" ng-bind-html="Product.products.report.stock_count | unsafe">0</strong>
                <p class="text-muted"> <i class="mdi mdi-checkbox-blank-circle-outline text-info"></i> Unidades disponíveis
                </p>
                <!-- ngIf: Product.products.report.stockPercentage > 50 -->

                <!-- ngIf: Product.products.report.stockPercentage <= 50 && Product.products.report.stockPercentage > 30 -->
                <!-- ngIf: Product.products.report.stockPercentage <= 30 --><div class="progress progress-bar-danger-alt ng-scope" ng-if="Product.products.report.stockPercentage <= 30">
                  <div class="progress-bar progress-bar-danger progress-bar-striped" role="progressbar" aria-valuenow="56" aria-valuemin="0" aria-valuemax="100" style="width:0%">
                  </div>
                </div><!-- end ngIf: Product.products.report.stockPercentage <= 30 -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 m-bottom-0">
        <div class="card m-bottom-0">
          <div class="card-body" style="padding-bottom: 10px;">
            <section class="text-center">
              <h4 class="text-left block  ng-binding">Margem de lucro total 0%</h4>
              <h1 class="reports-expenses text-left font-w-100 ng-binding" ng-bind-html="Product.products.report.all_value | money">R$ 0,00</h1>
              <div class="chart-insights text-left ">
                <p>Preço de custo</p>
                <h4><strong class="font-w-400 ng-binding" ng-bind-html="Product.products.report.buy_price | money">R$ 0,00</strong></h4>
              </div>
            </section>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <hr style="margin-bottom: 30px !important;">
    <div class="row">
      <div class="col-sm-6">
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn btn-info search-input prepend-input h-40" ng-click="Product.getMovimentSearch()"><i class="fa fa-search"></i></button>
          </div>
          <input name="" class="form-control search-input search-input-text ng-pristine ng-valid ng-empty ng-touched" placeholder="Pesquisar" ng-model="Product.filter.search">
          <div style="clear: both;"></div>
        </div>
        <div style="clear: both;"></div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4>Produtos</h4>
          </div>
          <div class="card-body np-top">
            <div class="text-center ng-scope">
              <img src="/assets/images/product.svg " class="nothing-image">
              <h3 class="nothing-text">Nenhum produto cadastrado.</h3>
              <a class="btn btn-primary ml-5" href="/product/new/"><span><i class="mdi mdi-plus-circle-outline"></i></span> Cadastrar novo produto</a>
              <br><br>
            </div><!-- end ngIf: Product.products.products == 0 -->
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <edit-provider-modal id="edit-provider-modal" @saveProvider="request"/>
    <goal-detail-modal id="goal-detail-modal" @updateGoal="request"/>
  </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LineChart } from "echarts/charts";
import { GaugeChart } from "echarts/charts";
import EditProviderModal from '@/components/EditProviderModal.vue';
import GoalDetailModal from '@/components/GoalDetailModal.vue';

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import AppService from '@/services/app.service';
import pace from 'pace-js';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
  GaugeChart,
]);

export default {
  name: 'Home',
  components: {
    GoalDetailModal,
    EditProviderModal,
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  data() {
    return {
      status: 'open',
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      monthStart: 0,
      cards: [],
      monthName: '',
      categories: [],
      wallets: [],
      filters: [
        {
          field: '',
          values: [
          ],
        },
      ],
      fields: [
        { label: 'Contas', value: 'account_id' },
        { label: 'Categoria', value: 'category' },
      ],
      filterAll: [
        { label: 'Cartão', value: 'card' },
        { label: 'Conta', value: 'account' },
      ],
      optionsDate: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 360,
        },
        locale: {
          format: 'DD/MM/YYYY',
          cancelLabel: 'Cancelar',
          // applyLabel: 'Filtrar',
          applyLabel: 'Ok',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      tabActive: 'tab-all',
      hourType: 'sun',
      hourTypeText: 'Bom dia',
      totalAccount: 0,
      from: '',
      to: '',
      no_card: true,
      month: '',
      report: {},
      providers: [],
      isLoading: true,
      monthsList: [
        { id: '01', name: 'Janeiro', index: 0},
        { id: '02', name: 'Fevereiro', index: 1},
        { id: '03', name: 'Março', index: 2},
        { id: '04', name: 'Abril', index: 3},
        { id: '05', name: 'Maio', index: 4},
        { id: '06', name: 'Junho', index: 5},
        { id: '07', name: 'Julho', index: 6},
        { id: '08', name: 'Agosto', index: 7},
        { id: '09', name: 'Setembro', index: 8},
        { id: '10', name: 'Outubro', index: 9},
        { id: '11', name: 'Novembro', index: 10},
        { id: '12', name: 'Dezembro', index: 11},
      ],
      months: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
      ],
    };
  },
  provide: {
  },
  mounted () {
    window.scrollTo(0, 0);
    this.monthStart = new Date().getMonth();
  },
  created() {
    moment.locale('pt_BR');
    this.monthStart = new Date().getMonth();
    this.month      = new Date().getMonth();
    this.monthName  = moment(new Date()).format('MMMM');

    this.from = `${moment(new Date()).format('YYYY-MM')}-01`;
    this.to = moment(this.from, "YYYY-MM-DD").endOf('month');

    this.$root.$on('register.provider', this.request);
    this.request();
  },
  methods: {
    deleteProvider(id) {
      AppService.deleteProvider(id).then(
        (response) => {
          this.isSending = false;
          console.log(response);
          this.$toast.show({
            title: "Sucesso",
            content: "Fornecedor removido!",
            type: "success",
          });
          this.request();
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: "Erro",
            content: "Erro interno, por favor tente novamente mais tarde",
            type: "error",
          });
        }
      );
    },
    removeProvider(id) {
      Swal.fire({
        title: `Remover fornecedor?`,
        text: 'Tem certeza que deseja remover esse fornecedor?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.deleteProvider(id);
        }
      });
    },
    createTransaction(id) {
      this.$root.$emit('show.modal', id);
    },
    editProvider(item) {
      this.$root.$emit('show.modal', 'edit-provider-modal', item);
    },
    budgetDetail(id, item) {
      this.$root.$emit('show.modal', id, item);
    },
    request() {
      pace.start();
      AppService.getProvider().then(
        (response) => {
          if (response.providers) {
            this.providers = response.providers;
          }
          if (response.status == 301) {
            this.$router.push({ name: 'Premium', params: { expire: true }});
          }
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    addFilter() {
      this.filters.push({
        field: '',
        values: null,
      });
    },
    removeFilter(n) {
      this.filters.splice(n, 1);
    },
    print () {
      window.print();
    },
    filterRequest() {
      this.request();
    },
  },
};
</script>

<style scoped lang="scss">
.cashflow-print {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.page-heading {
  margin-bottom: 0px;
}
.chart {
  height: 250px;
  top: -5px;
  left: 0;
  position: absolute;
}
.chart-line {
  height: 350px;
  margin-top: 0px;
}
.swiper-slide{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.swiper-container {
  height : 500px;
}
.figure-stats {
  background-color: #F9F9F9;
  border: 1.2px solid #E7EAEE;
  margin-top: 0px;
}
.card-header {
  border-bottom: none;
}
.figure-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
.heading-title {
  padding-left: 0px;
}
.total-amount {
  margin-top: 10px;
  display: block;
}
.btn-remove-filter {
  height: 35px !important;
  border-radius: 50%;
  width: 35px;
  padding: 0px !important;
  text-align: center;
}
.btn-remove-filter i {
  margin-right: 0;
}
.list-budget .transaction-progress .progress {
  height: 8px;
}
.btn-edit-category {
  width: 40%;
}
.btn-remove-category {
  width: 40%;
}
.budget-buttons {
  position: absolute;
  top: 0;
  width: 120px;
  right: 0;
}
@media only screen and (max-width: 570px){
  .budget-buttons {
    top: -40px !important;
  }
}
.include-card-expense {
  position: absolute;
  left: 20px;
  z-index: 1;
}
.list-group-item {
}
.list-group-item:hover {
  background: #f9f9f9 !important;
}
.action-add {
  position: absolute;
  right: 20px;
  top: 20px;
}
.prepend-input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.search-input-text {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
</style>
